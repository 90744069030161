import { Bone, Event, Spine, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import {
  BonusKind,
  EventTypes,
  GameMode,
  ISettledBet,
  IUserBalance,
  WheelBonus,
  bonusIds,
  lineSets,
} from '../../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringWheel,
  setIsShowSoundToast,
  setIsStopOnSunGodWheel,
  setIsWheelSpinBtnOn,
  setIsWheelWriteQuery,
  setLastRegularWinAmount,
  setNextResult,
  setReplayBet,
  setSlotConfig,
  setWheelBonuses,
  setWinAmount,
} from '../../../gql/cache';
import client from '../../../gql/client';
import { placeBetGql } from '../../../gql/mutation';
import { getUserGql, replayBetGql } from '../../../gql/query';
import { isFreeSpinsMode, nextTick } from '../../../utils';
import Tween from '../../animations/tween';
import { getResultFromTbl } from '../../announce/utils';
import { BgmControl } from '../../bgmControl/bgmControl';
import { GameViewLayout, REELBASE_SCALE, eventManager } from '../../config';
import { updateFreeRoundsTotalWin } from '../../freeRoundBonus/helper';
import { PachiDropCoinType } from '../config';

import {
  MONEYWHEEL_ITEM_NUM,
  MomeyWheelTypeToSkin,
  MoneyWheelDetailType,
  MoneyWheelGoldType,
  MoneyWheelSilverType,
  moneyWheelActConv,
  moneyWheelActTypeLot,
  moneyWheelNumToType,
  moneyWheelRotType,
} from './config';

type WheelInfo = {
  type: MoneyWheelDetailType;
  position: number;
  prize: MoneyWheelSilverType | MoneyWheelGoldType;
};

const WHEEL_PRIZE_CNT_MAX = 15;
const WHEEL_ONE_PRIZE_ROT = -360 / WHEEL_PRIZE_CNT_MAX;

class Wheel extends PIXI.Container {
  private currentSkin?: string;

  private spine: Spine;

  private spinInfo!: WheelInfo;

  private spinCnt!: number;

  private winAmount!: number;

  private wheelBone: Bone;

  private effectSpine: Spine;

  private wheelSpinBtn: Spine;

  private wheelInfo!: WheelBonus[];

  private adventFlg: boolean;

  constructor() {
    super();

    this.spine = new Spine(PIXI.Loader.shared.resources['Wheel']!.spineData!);

    this.effectSpine = new Spine(PIXI.Loader.shared.resources['WheelNusyoEff']!.spineData!);
    this.wheelSpinBtn = this.initWheelSpinBtn();

    this.addChild(this.effectSpine);
    this.addChild(this.spine);
    this.addChild(this.wheelSpinBtn);

    this.reset();

    this.wheelBone = this.spine.skeleton.findBone('prg_rot');

    this.position.set(GameViewLayout.ReelBase.width / 2, GameViewLayout.wheel.top);
    this.scale.set(REELBASE_SCALE);

    this.adventFlg = false;

    const rot0 = this.wheelBone.rotation;
    this.spine.state.addListener({
      start(entry) {
        if (entry.animation?.name.startsWith('rot_')) {
          AudioApi.play({ type: ISongs.XT002S_Wheel_Rotate_com });
        }
      },
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name.startsWith('rot_')) {
          this.showWin();
          let sound: ISongs = ISongs.XT002S_Wheel_Stop;
          switch (this.spinInfo.prize) {
            case 500:
            case 1000:
            case 3000:
              sound = ISongs.XT002S_JP;
              break;
            default:
              break;
          }
          AudioApi.play({ type: sound });
          this.spine.state.addAnimation(0, 'kettei_loop', true);
          eventManager.emit(EventTypes.PACHIDROP_WHEEL_SPIN_STOP);
          //PCNC-117
          AudioApi.stop({ type: ISongs.XT002S_Wheel_Rotate_com });
          AudioApi.stop({ type: ISongs.XT002S_BGM_Wheel_Rotate });
          AudioApi.stop({ type: ISongs.XT002S_Wheel_Rotate_aori_L });
          AudioApi.stop({ type: ISongs.XT002S_Wheel_Rotate_aori_R });
        } else if (entry.animation?.name === 'start') {
          this.showSpinBtn();
        }
      },
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'change_wheel') {
          this.spine.skeleton.setSkinByName(MomeyWheelTypeToSkin[this.spinInfo.type]);
        } else if (event.data.name === 'change_rot') {
          const destPos = this.spinInfo.position;
          this.wheelBone.rotation = destPos * WHEEL_ONE_PRIZE_ROT + rot0;
        } else if (event.data.name === 'aoriL') {
          AudioApi.play({ type: ISongs.XT002S_Wheel_Rotate_aori_L });
        } else if (event.data.name === 'aoriR') {
          AudioApi.play({ type: ISongs.XT002S_Wheel_Rotate_aori_R });
        }
      },
    });

    eventManager.addListener(EventTypes.PACHIDROP_SHOW_WHEEL, this.makeWheel.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END, this.winCountEnd.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_COIN_IN_EFFECT, this.coinInEffect.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_BTN_PUSH, this.btnPushed.bind(this));
  }

  private initWheelSpinBtn() {
    const btn = new Spine(PIXI.Loader.shared.resources['Wheel_Spin_btn']!.spineData!);
    btn.visible = false;
    const callback = () => {
      eventManager.emit(EventTypes.PACHIDROP_WHEEL_BTN_PUSH);
    };
    btn.on('click', callback);
    btn.on('touchend', callback);
    btn.interactive = true;
    return btn;
  }

  public setSkin(skinName: string) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;
    this.spine.skeleton.setSkinByName(this.currentSkin);
  }

  public reset() {
    this.spinInfo = {
      type: 'Silver1',
      position: 0,
      prize: 5,
    };
    this.spinCnt = 0;
    this.spine.visible = false;
    this.winAmount = 0;
    this.effectSpine.visible = false;

    this.wheelInfo = [];

    this.spine.state.setAnimation(0, 'base');
    this.spine.skeleton.setToSetupPose();
  }

  public getWheelInfoCnt(): number {
    return setWheelBonuses().totalRounds;
  }

  public makeWheel() {
    setIsDuringWheel(true);
    if (isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE, false);
    }

    const wheelBonus = setWheelBonuses();
    if (wheelBonus.totalRounds === 0) {
      eventManager.emit(EventTypes.PACHIDROP_DROP_END);
      return;
    }
    //sort
    wheelBonus.bonus.forEach((bonus: WheelBonus) => {
      if (bonus.bonusId === bonusIds[BonusKind.WHEEL_SILVER] || bonus.bonusId === bonusIds[BonusKind.FS_WHEEL_SILVER]) {
        for (let i = 0; i < bonus.rounds; i++) {
          this.wheelInfo.push(bonus);
        }
      }
    });
    wheelBonus.bonus.forEach((bonus: WheelBonus) => {
      if (bonus.bonusId === bonusIds[BonusKind.WHEEL_GOLD] || bonus.bonusId === bonusIds[BonusKind.FS_WHEEL_GOLD]) {
        for (let i = 0; i < bonus.rounds; i++) {
          this.wheelInfo.push(bonus);
        }
      }
    });

    if (this.wheelInfo.length === 0) {
      eventManager.emit(EventTypes.PACHIDROP_DROP_END);
    } else {
      BgmControl.stopBgm();
      const wheelStartDelay = Tween.createDelayAnimation(100);
      wheelStartDelay.addOnComplete(() => {
        this.advent();
        eventManager.emit(EventTypes.PACHIDROP_WHEEL_ADVENT);
      });
      wheelStartDelay.start();
    }
  }

  public advent() {
    this.spinCnt = 0;
    this.spine.visible = true;
    this.adventFlg = true;
    const silverCnt = this.wheelInfo.reduce<number>((cnt, bonus) => {
      if (bonus.bonusId === bonusIds[BonusKind.WHEEL_SILVER] || bonus.bonusId === bonusIds[BonusKind.FS_WHEEL_SILVER])
        cnt = cnt + 1;
      return cnt;
    }, 0);
    if (silverCnt) {
      this.spine.skeleton.setSkinByName(MomeyWheelTypeToSkin.Silver1);
    } else {
      this.spine.skeleton.setSkinByName(MomeyWheelTypeToSkin.Gold1);
    }
    this.spine.state.setAnimation(0, 'start');
    //PCNC-108
    BgmControl.stopBgm();
    AudioApi.play({ type: ISongs.XT002S_Opening_Sun });
    AudioApi.play({ type: ISongs.XT002S_BGM_Opening_Sun });
  }

  private showWin() {
    const winAnimation = Tween.createDelayAnimation(100);
    winAnimation.start();

    winAnimation.addOnComplete(() => {
      this.winAmount = this.spinInfo.prize;
      if (this.winAmount != 0) {
        eventManager.emit(EventTypes.PACHIDROP_WIN_START, this.winAmount * setBetAmount(), 'Wheel'); //kari
      } else {
        this.nextSpin();
      }
    });
  }

  private winCountEnd() {
    this.spine.state.setAnimation(0, 'base');
    const winAmount = this.winAmount * setBetAmount();
    updateFreeRoundsTotalWin(winAmount); //frb

    if (isFreeSpinsMode(setGameMode())) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      setLastRegularWinAmount(setLastRegularWinAmount() + winAmount);

      if (setWinAmount() === 0) {
        setWinAmount(winAmount);

        // To ensure that win values are rendered after updating
        nextTick(() => eventManager.emit(EventTypes.SHOW_WIN_LABEL));
      } else {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setLastRegularWinAmount());
      }
    }
    const delay = Tween.createDelayAnimation(1200);
    delay.addOnComplete(() => {
      this.nextSpin();
    });
    delay.start();
  }

  private showSpinBtn() {
    BgmControl.stopBgm();

    //stop auto spin
    if (setIsStopOnSunGodWheel()) {
      setIsAutoSpins(false);
      setIsContinueAutoSpinsAfterFeature(false);
    }

    if (setIsAutoSpins() || setIsContinueAutoSpinsAfterFeature()) {
      const delay = Tween.createDelayAnimation(300);
      delay.addOnComplete(() => {
        this.startSpin();
      });
      delay.start();
    } else {
      this.wheelSpinBtn.visible = true;
      this.wheelSpinBtn.state.setAnimation(0, 'in');
      this.wheelSpinBtn.state.addAnimation(0, 'base');
      setIsWheelSpinBtnOn(true);
      eventManager.emit(EventTypes.PACHIDROP_WHEEL_SHOW_BTN);
      AudioApi.play({ type: ISongs.XT002S_Wheel_Spin_Indicate });
    }
  }

  private hideSpinBtn() {
    const delay = Tween.createDelayAnimation(500);
    delay.start();
    delay.addOnComplete(() => {
      this.wheelSpinBtn.visible = false;
    });
  }

  private btnPushed() {
    if (setIsWheelSpinBtnOn()) {
      if (AudioApi.isRestricted) {
        BgmControl.handleChangeRestriction();
        setIsShowSoundToast(false);
      }
      this.wheelSpinBtn.state.setAnimation(0, 'push');

      if (
        this.wheelInfo[this.spinCnt]!.bonusId === bonusIds[BonusKind.WHEEL_GOLD] ||
        this.wheelInfo[this.spinCnt]!.bonusId === bonusIds[BonusKind.FS_WHEEL_GOLD]
      ) {
        if (this.spine.skeleton.skin?.name.includes('silver')) {
          this.spine.skeleton.setSkinByName('gold_01');
          //          this.adventFlg = true;
        }
      }
      if (this.adventFlg) {
        this.spine.state.setAnimation(0, 'flash_in');
        this.spine.state.addAnimation(0, 'flash_loop', true);
      } else {
        this.spine.state.setAnimation(0, 'flash_in');
        this.spine.state.addAnimation(0, 'flash_loop', true);
      }
      this.startSpin();
      this.hideSpinBtn();
      setIsWheelSpinBtnOn(false);
      AudioApi.play({ type: ISongs.XT002S_Wheel_Spin_Push });
    }
  }

  private async placeBet() {
    if (setReplayBet()) {
      const replayResult = await client.query<{ placeBet: ISettledBet }>({
        query: replayBetGql,
        variables: { betId: setReplayBet() },
      });
      return replayResult.data.placeBet;
    }

    const { data } = await client.mutate<{ placeBet: ISettledBet }>({
      mutation: placeBetGql,
      variables: {
        input: {
          slotId: setSlotConfig().id,
          coinAmount: setCoinAmount(),
          coinValue: setCoinValue(),
          lineSetId: lineSets[GameMode.REGULAR],
          userBonusId: this.wheelInfo[this.spinCnt]!.id,
        },
      },
      fetchPolicy: 'network-only',
    });

    return data!.placeBet;
  }

  private async startSpin() {
    const placeBet = await this.placeBet();

    const userData = client.readQuery<{ user: IUserBalance }>({
      query: getUserGql,
    });

    setNextResult({
      ...setNextResult()!,
      balance: placeBet.balance,
    });

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.settled,
        },
      },
    });
    setIsWheelWriteQuery(true);

    const moneyWheelInfo = placeBet.bet.data.features.wheel;
    const typeDetail = Math.floor(moneyWheelInfo!.value / MONEYWHEEL_ITEM_NUM);
    const position = moneyWheelInfo!.value % MONEYWHEEL_ITEM_NUM;
    const type =
      moneyWheelInfo?.wheel === 'GW' ? moneyWheelNumToType.Gold[typeDetail] : moneyWheelNumToType.Silver[typeDetail];

    this.spinInfo = {
      type: type!,
      position: position,
      prize: moneyWheelInfo!.reward as MoneyWheelSilverType | MoneyWheelGoldType,
    };

    //ホイール変更用のフラッシュとボタン押下後待機用のフラッシュは別で必要かも
    if (this.adventFlg) {
      this.spine.state.setAnimation(0, 'flash_out');
    } else {
      this.spine.state.setAnimation(0, 'flash_out');
    }
    this.adventFlg = false;

    this.spine.state.addAnimation(0, this.getSpinAnimationNameByLot());
    AudioApi.play({ type: ISongs.XT002S_BGM_Wheel_Rotate });
    eventManager.emit(EventTypes.PACHIDROP_WHEEL_SPIN_START);
  }

  private nextSpin() {
    this.spinCnt += 1;
    if (this.spinCnt >= this.wheelInfo.length) {
      eventManager.emit(EventTypes.PACHIDROP_WHEEL_ALL_END); //kari
    } else {
      this.showSpinBtn();
    }
  }

  private getSpinAnimationNameByLot(): string {
    const lotType = moneyWheelActConv[this.spinInfo.position];
    const wheelAnim = getResultFromTbl(moneyWheelActTypeLot[lotType!], Math.floor(Math.random() * 10));
    return moneyWheelRotType[wheelAnim] ?? 'rot_A';
  }

  public coinInEffect(coinType: PachiDropCoinType) {
    this.effectSpine.visible = true;
    this.effectSpine.skeleton.setSkinByName(coinType === 'GoldCoin' ? 'gold' : 'silver');
    this.effectSpine.state.setAnimation(0, 'base', false);
  }
}

export default Wheel;
