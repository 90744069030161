import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  PRELOAD_SPINE_TEXTURES,
  SPINE_ATLASES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusKind,
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  ISettledBet,
  IUserBalance,
  UserBonus,
  WheelBonus,
  bonusIds,
  freeRoundBonusId,
  reelSets,
} from '../../global.d';
import {
  setBetAmount,
  setBonuses, //setBrokenBuyFeatureGame,
  setBrokenGame,
  setBrokenGameBaseWheel,
  setBrokenGameFreeSpinsWheel,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsMobile,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setProgress,
  setReelSetId,
  setReplayBet,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setWheelBonuses,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  ReelSetType,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotBetGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { FREEGAME_TO_BASEGAME_REEL_POSITIONS, eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  addTextureCacheFromSpineAtlas,
  calcActiveUserBonusTotalWinAmount,
  findSubstituteCoinAmount,
  isMobileDevice,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  wait,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0]!.node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBonusGame = async () => {
      // TODO(FRB) Be sure to turn it ON when pushing to master
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      // TODO(FRB) Be sure to turn it OFF when pushing to master
      // ->its locally for testing.
      /*const originActiveUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      const activeUserBonusData: ApolloQueryResult<{
        userBonuses: UserBonus[];
      }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

      activeUserBonusData.data.userBonuses.push({
        ...(setCurrentBonus() as UserBonus),
        id: freeRoundBonusId,
        bonusId: freeRoundBonusId,
        coinAmount: 1,
        coinValue: 100,
        rounds: 2,
        totalWinAmount: 0,
        isActive: true,
        currentRound: 0,
      });
      // <-its locally for testing.
*/

      const freeSpinsBonus = activeUserBonusData.data.userBonuses.find(
        (ub) => ub.bonus.type === 'FREE_SPIN' || ub.bonus.id === bonusIds[BonusKind.FREE_SPINS],
      );
      if (freeSpinsBonus) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeSpinsBonus);

        setBrokenGame(true);

        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: freeSpinsBonus.roundsPlayed,
          totalRounds: freeSpinsBonus.rounds + freeSpinsBonus.roundsPlayed,
        });
        setCurrentFreeSpinsTotalWin(totalWinAmount);
      }

      //wheel
      activeUserBonusData.data.userBonuses.forEach((ub) => {
        if (
          ub.bonus.id === bonusIds[BonusKind.WHEEL_SILVER] ||
          ub.bonus.id === bonusIds[BonusKind.WHEEL_GOLD] ||
          ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_SILVER] ||
          ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_GOLD]
        ) {
          //setWheel
          const bonus: WheelBonus = {
            id: ub.id,
            bonusId: ub.bonusId,
            lineSetId: ub.lineSetId,
            coinValue: ub.coinValue,
            coinAmount: ub.bonus.coinAmount,
            totalWinAmount: ub.totalWinAmount,
            rounds: ub.rounds,
            betId: ub.betId,
          };
          const wheelBonuses = setWheelBonuses();
          wheelBonuses.currentRound = 0;
          wheelBonuses.totalRounds += bonus.rounds;
          wheelBonuses.bonus.push(bonus);

          if (
            ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_SILVER] ||
            ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_GOLD]
          ) {
            //check
            const totalWinAmount = calcActiveUserBonusTotalWinAmount(ub);
            setCurrentFreeSpinsTotalWin(totalWinAmount);

            setCurrentBonus({
              ...ub,
              rounds: 6,
              roundsPlayed: 6,
              id: bonusIds[BonusKind.FREE_SPINS]!,
              bonusId: bonusIds[BonusKind.FREE_SPINS]!,
              isActive: true,
              currentRound: 6,
              totalRounds: 6,
            });

            setBrokenGameFreeSpinsWheel(true);
            setBrokenGame(true);
            //setCurrentFreeSpinsTotalWin(0);
          } else {
            const totalWinAmount = calcActiveUserBonusTotalWinAmount(ub);
            setLastRegularWinAmount(totalWinAmount);
            setWinAmount(totalWinAmount);
            setBrokenGameBaseWheel(true);
          }
        }
      });

      const buyFeatureBonus = activeUserBonusData.data.userBonuses.find(
        (ub) => ub.bonus.id === bonusIds[BonusKind.BUY_FEATURE],
      );

      if (buyFeatureBonus) {
        setBrokenGame(true);

        const totalWinAmount = calcActiveUserBonusTotalWinAmount(buyFeatureBonus);
        setCurrentFreeSpinsTotalWin(totalWinAmount);

        setCurrentBonus({
          ...buyFeatureBonus,
          isActive: true,
          currentRound: buyFeatureBonus.roundsPlayed,
          totalRounds: buyFeatureBonus.rounds + buyFeatureBonus.roundsPlayed,
        });
      }

      const wheelBonus = activeUserBonusData.data.userBonuses.find(
        (ub) =>
          ub.bonus.id === bonusIds[BonusKind.WHEEL_SILVER] ||
          ub.bonus.id === bonusIds[BonusKind.WHEEL_GOLD] ||
          ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_SILVER] ||
          ub.bonus.id === bonusIds[BonusKind.FS_WHEEL_GOLD],
      );

      // TO DO check
      const freeRoundBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId);
      if (freeRoundBonus) {
        if (
          (!freeSpinsBonus && !buyFeatureBonus && !wheelBonus) ||
          freeSpinsBonus?.data.frbReferenceId ||
          buyFeatureBonus?.data?.frbReferenceId ||
          wheelBonus?.data?.frbReferenceId
        ) {
          const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
          setFreeRoundBonus({
            id: freeRoundBonus.id,
            bonusId: freeRoundBonus.bonusId,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            status: freeRoundBonus.status,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: totalWinAmount,

            isActive: true,
            currentRound: 0,
          });
          console.log('LoadScreen:FRB', freeSpinsBonus, freeSpinsBonus?.data.frbReferenceId); //test

          setFreeRoundsTotalWin(totalWinAmount);
        }
      }

      if (setReplayBet()) {
        const lastBetData = setUserLastBetResult();
        if (!lastBetData.userBonus && setWheelBonuses().bonus.length > 0) {
          const isSW = lastBetData.data.features.wheel?.wheel === 'SW';
          setUserLastBetResult({
            ...lastBetData,
            userBonus: {
              betId: setWheelBonuses().bonus[0]!.betId,
              bonusId: '', //TO DO
            },
            data: {
              features: {
                ...lastBetData.data.features,
                GW: lastBetData.data.features.GW + (isSW ? 0 : 1),
                SW: lastBetData.data.features.GW + (isSW ? 1 : 0),
              },
            },
          });
        } else if (freeSpinsBonus) {
          const gw =
            lastBetData.data.features.GW -
            lastBetData.data.features.pachiDrop.filter((pd) => pd.reward === 'GW').length;
          const sw =
            lastBetData.data.features.SW -
            lastBetData.data.features.pachiDrop.filter((pd) => pd.reward === 'SW').length;
          setUserLastBetResult({
            ...lastBetData,
            data: {
              features: {
                ...lastBetData.data.features,
                GW: gw,
                SW: sw,
              },
            },
          });
        }
      }
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0]!,
        settings: slot.settings,
      });
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );
      setGameMode(GameMode.REGULAR);

      let coinValue;
      let coinAmount;
      if (setBrokenGame()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      if (setWinAmount() === 0) {
        setWinAmount(setUserLastBetResult().result.winCoinAmount);
      }
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
    };

    const getLastBetFromBonusBetId = async () => {
      const lastBetData = setUserLastBetResult();
      if (lastBetData.result.reelPositions.length !== 0) {
        //pcnc-104
        //Reel correction when returning to the base game
        if (
          !setBrokenGame() &&
          (lastBetData.reelSetId === reelSets[GameMode.FREE_SPINS] ||
            lastBetData.reelSetId === reelSets[GameMode.BUY_FEATURE])
        ) {
          setUserLastBetResult({
            ...lastBetData,
            reelSetId: reelSets[GameMode.REGULAR]!,
            result: {
              ...lastBetData.result,
              reelPositions: FREEGAME_TO_BASEGAME_REEL_POSITIONS,
            },
          });
        }
        return;
      }
      if (!lastBetData.userBonus.betId) {
        setUserLastBetResult({
          ...lastBetData,
          reelSetId: reelSets[GameMode.REGULAR]!,
          result: {
            ...lastBetData.result,
            reelPositions: [0, 0, 0],
          },
        });
        return;
      }
      const bet = await client.query<ISettledBet>({
        query: slotBetGql,
        variables: { input: { id: lastBetData.userBonus.betId } },
        fetchPolicy: 'network-only',
      });
      if (bet.data.bet.result) {
        //if mode changed from freegame to basegame
        if (
          !setBrokenGame() &&
          (bet.data.bet.reelSetId === reelSets[GameMode.FREE_SPINS] ||
            bet.data.bet.reelSetId === reelSets[GameMode.BUY_FEATURE])
        ) {
          setUserLastBetResult({
            ...lastBetData,
            reelSetId: reelSets[GameMode.REGULAR]!,
            result: {
              ...lastBetData.result,
              reelPositions: FREEGAME_TO_BASEGAME_REEL_POSITIONS,
            },
          });
        } else {
          setUserLastBetResult({
            ...lastBetData,
            result: bet.data.bet.result,
            reelSetId: bet.data.bet.reelSetId,
          });
        }
      } else {
        setUserLastBetResult({
          ...lastBetData,
          reelSetId: reelSets[GameMode.REGULAR]!,
          result: {
            ...lastBetData.result,
            reelPositions: [0, 0, 0],
          },
        });
      }
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobileDevice());
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });
        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBonusGame();
        await getSlotData();
        await getLastBetFromBonusBetId();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(SPINE_ATLASES, process.env.PUBLIC_URL);
        await loadPixiAssets(
          [
            ...SPINE_LOADER_TEXTURES(setIsMobile()),
            ...PRELOAD_SPINE_TEXTURES,
            ...LOADER_SPRITE_TEXTURES,
            ...LOADER_TEXTURES,
          ],
          process.env.PUBLIC_URL,
        );

        addTextureCacheFromSpineAtlas('spine/');

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async () => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: true,
          isSoundEnabled: isSoundOn,
          onSuspended: setIsSuspended,
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioApi.isRestricted && !(!AudioApi.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
        await wait(1500);
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']!}>
      <div className={styles['logo']!}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']!}
        />
      </div>
      <ProgressBar
        className={styles['progressBar']!}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
